// themecolor
$themecolor: #2f577c;

$primarybtn: #2f577c;

$white: #fff;

$mate-black: #212121;

$black: #474646;

$gradient-one: #3461a3;

$gradient-two: #3a6bb4;

$footer-back-color:#192436;

$header-top-color:#192436;

$breadcrumbs-color:#192436;
